@import url("common.css");

/* Page Section */
.root {
  height: 100vh;
}

.page-area {
  background-color: var(--color-0);
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: yellow solid;
  border-width: var(--debug-border-medium);
}

/* Body Section */
.body-area {
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: space-between;
  border: black solid;
  border-width: var(--debug-border-medium);
}

/* Footer Section */
.page-footer-area {
  margin: 5px;
  border: black solid;
  border-width: var(--debug-border-medium);
}

/* Advertising 1 Section */
.advert1-area {
  width: 200px;
  margin: 5px;
  border: red solid;
  border-width: var(--debug-border-medium);
}
