@import url("../common.css");

.nav-area {
  /* debug */
  border: red solid;
  border-width: var(--debug-border-medium);
  /* End debug */

  width: 200px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  /* background-color: var(--color-4); */
  border: 3px solid var(--color-4);
  ;}

.nav-title {
  text-align: center;
  font-size: var(--font-small);
  font-weight: bold;
  color: var(--color-0);
  background-color: var(--color-3);
  border-radius: 5px;
  /* debug */
  border-bottom: red solid;
  border-width: var(--debug-border-medium);
}

.nav-item {
  margin: 5px;
  /* debug */
  border: purple solid;
  border-width: var(--debug-border-small);
}

.nav-page-link {
	color: var(--color-4);
	background-color: var(--color-0);
  text-decoration: none;
	/* padding: 5px; */
	border-radius: 5px;
	border: none;
	transition: all 0.4s ease 0s;
}
.nav-page-title {
  font-size: var(--font-small);
}

.nav-dateline {
  font-size: var(--font-xsmall);
}

.nav-page-link:hover {
  color: green;
  background-color: white;
	text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
	-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	transition: all 0.4s ease 0s;
}
