@import url("../common.css");

/* Heading Section */
.title-area {
  /* debug */
  border: black solid;
  border-width: var(--debug-border-medium);
  /* end debug */
  display: flex;
  flex-direction: column;
  /* margin-bottom: 40px; */
}

.site-title {
  /* debug */
  /* border: blue solid;
  border-width: var(--debug-border-small); */
  /* end debug */
  text-align: center;
  font-size: var(--font-medium);
  font-weight: bold;
  margin: 5px;
  padding-top: 7px;
  border-top: 1px solid var(--color-4);
}

.site-tagline {
  /* end debug */
  /* border: blue solid;
  border-width: var(--debug-border-small); */
  /* end debug */
  text-align: center;
  font-size: var(--font-small);
  font-style: italic;
  margin: 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--color-4);
}

.hr {
  color: black;
}