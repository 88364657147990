:root{
  /* Font Sizes */
  --font-xsmall: 12px;
  --font-small: 18px;
  --font-medium: 30px;
  --font-large: 60px;
  --font-light: 200;
  --font-average: 300;
  --font-heavy: 400;

  --color-0: #f2f1ef;
  --color-1: #d8cfd0;
  --color-2: #b1a6a4;
  --color-3: #413f3d;
  --color-4: #697184;

  --mycolor: var(--color-4);
  /* --debug-border-small: 1px;
  --debug-border-medium: 3px;
  --debug-border-large: 5px;  */

  --debug-border-small: 0px;
  --debug-border-medium: 0px;
  --debug-border-large: 0px; 
}

HTML {
  /* font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}

/* font-size: 16.66667px;
font-family: var(--font-base, "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif);
line-height: 1.6; */
/* font-family:  */
