@import url("../common.css");

/* Content Section */
.content-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px;
  color: var(--color-4);
  /* debug */
  border: red solid;
  border-width: var(--debug-border-medium);
}

.storytitle-area {
  text-align: center;
  font-size: var(--font-large);
  margin: 5px;
  border: green solid;
  border-width: var(--debug-border-small);
}

.storypicture-area {
  text-align: center;
  margin: 5px;
  border: green solid;
  border-width: var(--debug-border-small);
}

.story-picture-image {
  max-height: 500px;
  max-width: 1000px;
  margin: 5px;
}

.dateline-area {
  text-align: center;
  font-size: var(--font-small);
  font-style: italic;
  margin: 5px;
}

.storytext-area {
  margin: 5px;
  border: green solid;
  border-width: var(--debug-border-small);
}

.story-text {
  margin: 5px;
}